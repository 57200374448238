
import { Component, Emit, Vue } from 'vue-facing-decorator'
import { PaymentInstrumentType } from '@/lib/types'
import { Flow } from '@/lib/Flow'
import { State } from '@/lib/State'
import { viewModel } from '@/lib/AppService'

type PaymentTab = {
    type: PaymentInstrumentType,
    name: string,
    style: string,
    icon: string
}

@Component
export default class PaymentOptions extends Vue {
    @Emit paymentOptionChanged() {
        return 'Payment Selection Changed'
    }
    
    private defaultTabStyle = 'pay tab'
    private selectedTabStyle = 'pay-selected tab'
    private bankIcon = 'fa-solid fa-bank'
    private cardIcon = 'fa-solid fa-credit-card'
    private defaultIcon = 'fa-solid fa-money-bill-1'

    vm = viewModel

    get selectedTabType(): PaymentInstrumentType {
        return this.vm.selectedPaymentOption?.paymentInstrumentType
            ?? PaymentInstrumentType.None
    }

    get paymentTabs(): PaymentTab[] {
        if (!this.vm.paymentOptions || this.vm.paymentOptions.length == 0)
            return []
        return this.vm.paymentOptions.map(p => {
            const type = p.paymentInstrumentType
            return {
                type,
                name: type == PaymentInstrumentType.BankAccount
                    ? 'Bank Account'
                    :type == PaymentInstrumentType.BankTransfer
                    ? 'Bank Transfer'
                    : type == PaymentInstrumentType.CreditCard
                        ? 'Credit / Debit Card'
                        : type == PaymentInstrumentType.ECheck
                            ? 'E-Check'
                            : type == PaymentInstrumentType.EWallet
                                ? 'E-Wallet'
                                : 'Unknown',
                style: type == this.selectedTabType
                    ? this.selectedTabStyle
                    : this.defaultTabStyle,
                icon: type == PaymentInstrumentType.BankAccount
                    ? this.bankIcon
                    : type == PaymentInstrumentType.CreditCard
                        ? this.cardIcon
                        : this.defaultIcon
            }
        })
    }

    get headerMessage(): string {
        switch (this.vm.flow) {
            case Flow.CreateProfile:
            case Flow.CreateProfileAndAuthorize:
                return 'Setup Payment Profile'
                      
            case Flow.CreateProfileAuthorizeAndCapture:
                return 'Pay your Premium'
                    }
        return 'Invalid flow'
    }

    get linksMessage(): string {
        switch (this.vm.flow) {
            case Flow.CreateProfile:
            case Flow.CreateProfileAndAuthorize:
                return 'To save a profile, please select a payment option'     
            case Flow.CreateProfileAuthorizeAndCapture:
                return 'Choose from the options below to pay your premium of '
        }
        return 'Invalid flow'
    }

    get transactionamountvalue(): string {
        switch (this.vm.flow) {
            case Flow.CreateProfile:
            case Flow.CreateProfileAndAuthorize:
                return ''                          
            case Flow.CreateProfileAuthorizeAndCapture:
                return '$'
                    + this.vm.payload?.amount?.toFixed(2)
                    }
        return 'Invalid flow'
    }


    get optionsDisabled() {
        return !(this.vm.state == State.PaymentOptionsLoaded
            || this.vm.state == State.VendorDataLoaded)
            ? true
            : undefined
    }

    async onPaymentSelected(type: PaymentInstrumentType) {
        if (this.optionsDisabled)
            return
        const index = this.getPaymentLinkIndex(type)
        if (index < 0)
            return
        await this.vm.selectPaymentOption(type)
        this.paymentTabs.forEach(p => {
            p.style = p.type == type
                ? this.selectedTabStyle
                : this.defaultTabStyle
        })
        this.paymentOptionChanged()
            }

    private getPaymentLinkIndex(type: PaymentInstrumentType): number {
        return this.paymentTabs.findIndex(p => p.type == type)
    }
}
